import { gql } from '@apollo/client';

export const FOLLOW_USER = gql`
  mutation FollowUser($followerId: Int!, $followingId: Int!) {
    followUser(followerId: $followerId, followingId: $followingId) {
      id
      profile {
        name
      }
      avatar {
        fileUrl
      }
    }
  }
`;

export const UNFOLLOW_USER = gql`
  mutation UnfollowUser($followerId: Int!, $followingId: Int!) {
    unfollowUser(followerId: $followerId, followingId: $followingId) {
      id
      profile {
        name
      }
      avatar {
        fileUrl
      }
    }
  }
`;

export const GET_USER_FOLLOWERS = gql`
  query GetFollowers($userId: Int!) {
    getFollowers(userId: $userId) {
      id
      profile {
        name
      }
      avatar {
        fileUrl
      }
    }
  }
`;

export const GET_USER_FOLLOWING = gql`
  query GetFollowing($userId: Int!) {
    getFollowing(userId: $userId) {
      id
      profile {
        name
      }
      avatar {
        fileUrl
      }
    }
  }
`;

export const CHECK_IS_FOLLOWING = gql`
  query CheckIsFollowing($followerId: Int!, $followingId: Int!) {
    isFollowing(followerId: $followerId, followingId: $followingId)
  }
`;