import React, { createContext, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FOLLOW_USER, UNFOLLOW_USER, CHECK_IS_FOLLOWING } from '../graphql/USER_FOLLOW';

interface FollowContextType {
  isFollowing: boolean;
  followUser: (userId: string | number) => Promise<void>;
  unfollowUser: (userId: string | number) => Promise<void>;
}

export const FollowContext = createContext<FollowContextType>({
  isFollowing: false,
  followUser: async () => await Promise.resolve(),
  unfollowUser: async () => await Promise.resolve()
});

export const FollowContextProvider: React.FC<{
  children: React.ReactNode;
  currentUserId?: number;
  targetUserId?: number;
}> = ({ children, currentUserId, targetUserId }) => {
  const { data: followingData } = useQuery(CHECK_IS_FOLLOWING, {
    variables: {
      followerId: Number(currentUserId),
      followingId: Number(targetUserId)
    },
    skip: !currentUserId || !targetUserId
  });

  const [isFollowing, setIsFollowing] = useState(followingData?.isFollowing || false);

  useEffect(() => {
    if (followingData?.isFollowing !== undefined) {
      setIsFollowing(followingData.isFollowing);
    }
  }, [followingData]);

  const [followUserMutation] = useMutation(FOLLOW_USER, {
    onError: (error) => {
      console.error('Error following user:', error);
      setIsFollowing(false);
    },
    onCompleted: (data) => {
      if (data?.followUser) {
        setIsFollowing(true);
      }
    }
  });

  const [unfollowUserMutation] = useMutation(UNFOLLOW_USER, {
    onError: (error) => {
      console.error('Error unfollowing user:', error);
      setIsFollowing(true);
    },
    onCompleted: (data) => {
      if (data?.unfollowUser) {
        setIsFollowing(false);
      }
    }
  });

  const followUser = async (userId: string | number) => {
    if (!currentUserId) return;
    try {
      await followUserMutation({
        variables: {
          followerId: Number(currentUserId),
          followingId: Number(userId)
        },
        refetchQueries: ['GetFollowers', 'GetFollowing']
      });
    } catch (error) {
     console.log(error)
    }
  };

  const unfollowUser = async (userId: string | number) => {
    if (!currentUserId) return;
    try {
      await unfollowUserMutation({
        variables: {
          followerId: Number(currentUserId),
          followingId: Number(userId)
        },
        refetchQueries: ['GetFollowers', 'GetFollowing']
      });
    } catch (error) {
     console.log(error)
    }
  };

  return (
    <FollowContext.Provider value={{ isFollowing, followUser, unfollowUser }}>
      {children}
    </FollowContext.Provider>
  );
};