import type { ReactNode } from 'react'
import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS_BY_FILTER } from '../graphql/GET_ALL_USERS_BY_FILTER';
import { searchClient } from '../components/Search/core';
import { UserData } from '../types/user';

export enum EUserFilterTypes {
  default = 'ALL_USERS',
  search = 'FILTERED_USERS_FROM_QUERY_STRING',
  peopleIamLookingFor = 'PEOPLE_I_AM_LOOKING_FOR_FILTER',
  peopleLookingForMe = 'PEOPLE_LOOKING_FOR_ME_FILTER'
}

export const PAGE_LIMIT = 12;

interface ICreativeListState {
  creatives: UserData[];
  error: any;
  filterType: EUserFilterTypes;
  isLoading: boolean;
  onFetchMore: (page: number) => Promise<void>;
  page: number;
  setFilterType: (type: EUserFilterTypes) => void;
  totalCreatives: number;
}

const initialState: ICreativeListState = {
  creatives: [],
  error: undefined,
  filterType: EUserFilterTypes.default,
  isLoading: false,
  onFetchMore: async (_: number) => {
    console.log('Default onFetchMore', _);
  },
  page: 1,
  setFilterType: (_: EUserFilterTypes) => {
    console.log('Default setFilterType', _);
  },
  totalCreatives: 0
};

export const CreativeListContext = createContext<ICreativeListState>(initialState);

interface ICreativeListProviderProps {
  children: ReactNode;
}

export const CreativeListContextProvider = (
  props: ICreativeListProviderProps
) => {
  const [creatives, setCreatives] = useState<UserData[]>([]);
  const [totalCreatives, setTotalCreatives] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [pageNum, setPageNum] = useState<number>(2);

  const queryString = window.location.search;
  let filterTypes = EUserFilterTypes.default;
  let searchStr;

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    searchStr = urlParams.get('q');
    if (searchStr) {
      filterTypes = EUserFilterTypes.search;
    }
  }

  const [filterType, setFilterType] = useState<EUserFilterTypes>(filterTypes);

  // Handle Algolia search
  const handleAlgoliaSearch = async (page = 0) => {
    try {
      setIsLoading(true);
      const response = await searchClient.search([
        {
          indexName: 'user',
          params: {
            query: searchStr ?? '',
            hitsPerPage: PAGE_LIMIT,
            page
          }
        }
      ]);

      const hits = response.results[0].hits;
      return {
        users: hits,
        page: page + 1,
        count: response.results[0].nbHits
      };
    } catch (err) {
      console.error('Algolia search error:', err);
      setError(err);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // GraphQL query setup
  const { loading: graphqlLoading, error: graphqlError, data: graphqlData, fetchMore } = useQuery(
    GET_ALL_USERS_BY_FILTER,
    {
      variables: {
        filterType,
        limit: PAGE_LIMIT,
        page: 1
      },
      skip: !!searchStr // Skip GraphQL query if we're using Algolia search
    }
  );

  // Effect to handle initial data fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      if (searchStr) {
        const searchResults = await handleAlgoliaSearch(0);
        if (searchResults) {
          setCreatives(searchResults.users);
          setTotalCreatives(searchResults.count);
        }
      }
    };
    fetchInitialData();
  }, [searchStr, filterType]);

  // Handle regular GraphQL data
  useEffect(() => {
    if (!searchStr && graphqlData) {
      setCreatives(graphqlData.getAllUsersByFilter.users);
      setTotalCreatives(graphqlData.getAllUsersByFilter.count);
    }
  }, [graphqlData, searchStr]);

  const onFetchMore = async (): Promise<void> => {
    setPageNum(prevNum => prevNum + 1);
    if (searchStr) {
      const searchResults = await handleAlgoliaSearch(pageNum - 1);
      if (searchResults) {
        setCreatives(prev => [...prev, ...searchResults.users]);
      }
    } else {
      await fetchMore({
        updateQuery: (cache, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return cache;
          }
          return {
            ...cache,
            getAllUsersByFilter: {
              ...cache.getAllUsersByFilter,
              users: [
                ...cache.getAllUsersByFilter.users,
                ...fetchMoreResult.getAllUsersByFilter.users
              ]
            }
          };
        },
        variables: {
          limit: PAGE_LIMIT,
          page: pageNum
        }
      });
    }
  };

  const contextValue: ICreativeListState = {
    creatives,
    error: error ?? graphqlError,
    filterType,
    isLoading: isLoading || graphqlLoading,
    onFetchMore,
    page: pageNum,
    setFilterType,
    totalCreatives
  };

  return (
    <CreativeListContext.Provider value={contextValue}>
      {props.children}
    </CreativeListContext.Provider>
  );
};