// src/types/user.ts
import { GET_USER_getUser } from '../graphql/types/GET_USER';
import { ADD_USER_PROFESSION_addUserProfession_professions } from '../graphql/types/ADD_USER_PROFESSION';

export interface IAlgoliaUser {
  id: number;
  email: string;
  avatar: string;
  hasAvatar: boolean;
  skills: string[];
  professions: string[];
  professionsCombined: string;
  name: string;
  bio: string | null;
  website: string | null;
  collaborationStatus: string | null;
  objectID: string;
  desiredCollaborationProfession?: {
    label: string;
  } | null;
}

export type UserData = GET_USER_getUser | IAlgoliaUser;

// Type guard to check if user data is from Algolia
export function isAlgoliaUser(user: UserData): user is IAlgoliaUser {
  return 'hasAvatar' in user && 'professionsCombined' in user;
}

// Helper functions to safely get user data
export const getUserName = (user: UserData): string => {
  if (isAlgoliaUser(user)) {
    return user.name;
  }
  return user.profile.name ?? '';
};

export const getUserAvatar = (user: UserData): string => {
  if (isAlgoliaUser(user)) {
    return user.hasAvatar ? user.avatar : '';
  }
  return user.avatar?.fileUrl ?? '';
};

export const getUserBio = (user: UserData): string => {
  if (isAlgoliaUser(user)) {
    return user.bio ?? '';
  }
  return user.profile.bio ?? '';
};

export const getUserProfessions = (user: UserData): { name: string }[] => {
  if (isAlgoliaUser(user)) {
    return user.professions.map((prof) => ({ name: prof }));
  }
  return (
    (user.professions as ADD_USER_PROFESSION_addUserProfession_professions[]) ??
    []
  );
};

export const getUserCollaborationStatus = (user: UserData): string => {
  if (isAlgoliaUser(user)) {
    return user.collaborationStatus ?? '';
  }
  return user.profile.collaborationStatus ?? '';
};

export const getUserDesiredCollaboration = (
  user: UserData
): { label: string } | null => {
  if (isAlgoliaUser(user)) {
    return user.desiredCollaborationProfession ?? null;
  }
  return user.desiredCollaborationProfession ?? null;
};
