import type { MouseEvent, SyntheticEvent } from 'react';
import React, {
  Fragment,
  useContext,
  useState
} from 'react';
// import useReactRouter from 'use-react-router';
import { useLocation } from 'react-router-dom'
import profileBackdrop from '../../assets/images/profile/profile-backdrop.jpg';
import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import envelopeOpenMessageWhite from '../../assets/svg/envelope-open-message-white.svg';
import settingsPrimaryIcon from '../../assets/svg/settings-primary.svg';
import userIcon from '../../assets/svg/user-icon.svg';
import { MessageContext } from '../../contexts/messageContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { GET_USER_getUser } from '../../graphql/types/GET_USER';
import { prependUrl } from '../../services/url';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import showMore from '../../utils/showMore';
import { PrettyContent } from '../Content';
import ToggleShowMore from '../ShowMore';
import CollaborationStatus, {
  ECollaborationStatus
} from './CollaborationStatus';
import { DEFAULT_MEDIA_AVATAR } from './UserCreativeCard';
import { FollowContext, FollowContextProvider } from '../../contexts/followContext';

interface IUserProfileProps {
  setIsUserProfileModal: (flag: boolean) => void;
  currentUser: GET_CURRENT_USER_me;
  // user would only exist if the you're viewing other user'rs profile
  user?: GET_USER_getUser;
}

const UserProfile: React.FunctionComponent<IUserProfileProps> = (props: IUserProfileProps) => {
  const { currentUser, user } = props;
  return (
    <FollowContextProvider currentUserId={Number(currentUser.id)} targetUserId={user ? Number(user.id) : undefined}>
      <UserProfileContent {...props} />
    </FollowContextProvider>
  );
};

const UserProfileContent: React.FunctionComponent<IUserProfileProps> = (props: IUserProfileProps) => {
  const { currentUser, user } = props;
  const location = useLocation();
  const isShown = showMore(location.search);
  const { actions } = useContext(MessageContext);
  const { isFollowing, followUser, unfollowUser } = useContext(FollowContext);
  const [bioShowMore, setBioShowMore] = useState(isShown);

  const userDetails = user ?? currentUser;

  const userAvatar = userDetails.avatar && userDetails.avatar.fileUrl;
  const compressedUserAvatar =
    getCompressedImagePath(userAvatar, 'thumbnail') || profilePlaceholder;

  const isDefaultMediaAvatar = userAvatar === DEFAULT_MEDIA_AVATAR;

  const MAX_BIO_LENGTH = 200;

  const backgroundImageUrl = isDefaultMediaAvatar
    ? userIcon
    : compressedUserAvatar;

  const isCurrentUser = currentUser.id === userDetails.id;
  const userBio = userDetails.profile.bio;

  const toggleBioShowMore = (e: SyntheticEvent) => {
    e.preventDefault();
    setBioShowMore(!bioShowMore);
  };

  const handleOnMessageButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    return actions.handleSelectOtherUser(user);
  };

  const handleShowModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.setIsUserProfileModal(true);
  };

  const handleFollowClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!user) return;

    try {
      if (isFollowing) {
        await unfollowUser(String(user.id));
      } else {
        await followUser(String(user.id));
      }
    } catch (error) {
      console.error('Error handling follow/unfollow:', error);
    }
  };

  const FooterButton = ({
    btnClass,
    imgSrc,
    imgAlt,
    onClickCb,
    text,
    textColor,
    withIcon = false
  }: {
    btnClass: string;
    imgSrc?: string;
    imgAlt?: string;
    onClickCb: (e: MouseEvent<HTMLButtonElement>) => void;
    text: string;
    textColor: string;
    withIcon?: boolean;
  }) => (
    <button
      className={`button is-borderless is-bordered-radius ${btnClass}`}
      onClick={onClickCb}
    >
      {withIcon && (
        <span className="icon">
          <img src={imgSrc} alt={imgAlt} className="image is-16x16 is-inline" />
        </span>
      )}
      <span className={`is-small has-text-weight-semibold ${textColor}`}>
        {text}
      </span>
    </button>
  );

  return (
    <div className="card is-dropshadowed page-user-profile-card is-rounded">
      <header className="card-header page-user-profile-card-header is-block">
        <div className="upper page-user-profile-card-header-upper">
          <figure className="backdrop page-user-profile-card-header-backdrop">
            <img src={profileBackdrop} alt="backdrop" />
          </figure>
        </div>
        <div className="lower page-user-profile-card-header-lower">
          <div className="level page-user-profile-card-header-lower-level m-b-1">
            <div className="level-item has-text-centered">
              <figure
                className={`image avatar is-112x112 page-user-profile-card-header-lower-level-figure ${isDefaultMediaAvatar &&
                  'default-media-avatar'}`}
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
              />
            </div>
          </div>
          <div className="has-text-centered p-r-1 p-b-1 p-l-1">
            <p className="name is-size-5">{userDetails.profile.name}</p>
            <p className="job-description is-small">
              {userDetails.skills &&
                userDetails.skills.map((skill, idx) => (
                  <span key={idx} className="is-size-6">
                    {(idx ? ', ' : '') + skill.name}
                  </span>
                ))}
            </p>
          </div>
        </div>
      </header>
      <div className="card-content is-small">
        {userDetails.professions.length !== 0 && (
          <p className="professions m-b-1">
            {userDetails.professions.map((profession, idx) => (
              <span key={idx}>{(idx ? ', ' : '') + profession.name}</span>
            ))}
          </p>
        )}
        {userDetails.profile && userDetails.profile.website && (
          <p className="website m-b-1">
            <a
              href={`${prependUrl(userDetails.profile.website)}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {userDetails.profile.website}
            </a>
          </p>
        )}
        {userDetails.profile && userBio && userBio.length > MAX_BIO_LENGTH ? (
          <p className="m-b-1">
            {bioShowMore ? (
              <Fragment>
                <PrettyContent content={userBio} isLineBreakEnabled={true} />
                <ToggleShowMore
                  toggle={toggleBioShowMore}
                  isShowMore={bioShowMore}
                />
              </Fragment>
            ) : (
              <Fragment>
                <PrettyContent
                  content={userBio.substr(0, MAX_BIO_LENGTH)}
                  isLineBreakEnabled={true}
                />
                ...
                <ToggleShowMore
                  toggle={toggleBioShowMore}
                  isShowMore={bioShowMore}
                />
              </Fragment>
            )}
          </p>
        ) : (
          userBio &&
          userBio.length > 0 && (
            <p className="m-b-1">
              <PrettyContent content={userBio} isLineBreakEnabled={true} />
            </p>
          )
        )}
        {userDetails.profile.collaborationStatus && (
          <CollaborationStatus
            collaborationStatus={
              userDetails.profile.collaborationStatus as ECollaborationStatus
            }
          />
        )}
      </div>
      <footer className="card-footer card-footer-padding p-0">
        {isCurrentUser ? (
          <FooterButton
            text="Edit Information"
            imgSrc={settingsPrimaryIcon}
            imgAlt="settings-primary-icon"
            onClickCb={handleShowModal}
            btnClass="has-background-grey is-fullwidth"
            textColor="has-text-primary"
          />
        ) : (
          <div className="buttons is-flex is-flex-direction-column m-0 p-0 is-fullwidth-mobile">
            <FooterButton
              btnClass={`has-background-info ${isFollowing ? 'is-light' : ''} is-fullwidth m-0`}
              text={isFollowing ? 'Following' : 'Follow'}
              onClickCb={handleFollowClick}
              textColor="has-text-white"
            />
            <FooterButton
              withIcon={true}
              imgAlt="envelope-message-icon"
              btnClass="has-background-primary is-primary is-fullwidth m-0"
              imgSrc={envelopeOpenMessageWhite}
              text="Message"
              onClickCb={handleOnMessageButtonClick}
              textColor="has-text-white"
            />
          </div>
        )}
      </footer>
    </div>
  );
};

export default UserProfile;
