import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CreativeListContext } from '../../contexts/creativeListContext';
import { EUserFilterTypes } from '../../pages/creatives';

const CreativesFilter: FC = () => {
  const { filterType, setFilterType } = useContext(CreativeListContext);
  const history = useHistory();
  const hasSearchParams = new URLSearchParams(window.location.search).has('q');

  const handleFilterClick = (type: EUserFilterTypes) => {
    if (hasSearchParams) {
      history.replace({ search: '' });
    }
    setFilterType(type);
  };

  return (
    <div className="column page-creatives-sidebar">
      <div className="columns">
        <div className="column">
          <div className="card page-creatives-sidebar-card">
            <div className="control is-bordered-bottom p-l-1 m-b-0">
              <input
                onChange={() => handleFilterClick(EUserFilterTypes.default)}
                checked={
                  !hasSearchParams && filterType === EUserFilterTypes.default
                }
                type="radio"
                className="is-radio"
                name="people"
                id="default"
              />
              <label
                htmlFor="default"
                className="is-block p-t-1 p-r-1 p-b-1 p-l-2 m-b-0 is-small"
              >
                Discover creatives
              </label>
            </div>
            <div className="control is-bordered-bottom p-l-1 m-b-0">
              <input
                onChange={() =>
                  handleFilterClick(EUserFilterTypes.peopleLookingForMe)
                }
                checked={
                  !hasSearchParams &&
                  filterType === EUserFilterTypes.peopleLookingForMe
                }
                type="radio"
                className="is-radio"
                name="people"
                id="peopleLookingForYourSkills"
              />
              <label
                htmlFor="peopleLookingForYourSkills"
                className="is-block p-t-1 p-r-1 p-b-1 p-l-2 m-b-0 is-small"
              >
                People looking for your skills
              </label>
            </div>
            <div className="control p-l-1">
              <input
                onChange={() =>
                  handleFilterClick(EUserFilterTypes.peopleIamLookingFor)
                }
                checked={
                  !hasSearchParams &&
                  filterType === EUserFilterTypes.peopleIamLookingFor
                }
                type="radio"
                className="is-radio"
                name="people"
                id="peopleYouAreLookingFor"
              />
              <label
                htmlFor="peopleYouAreLookingFor"
                className="is-block m-l-0 p-t-1 p-r-1 p-b-1 p-l-2 is-small"
              >
                People you are looking for
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="columns">
       <div className="column">
         <div className="card page-creatives-sidebar-card">
           <div className="control page-creatives-sidebar-card-control is-bordered-bottom has-icons-left m-b-0">
             <div className="select page-creatives-sidebar-card-control-select">
               <select className="has-background-white has-text-grey-dark is-borderless">
                 <option>Worldwide 1</option>
                 <option>Worldwide 2</option>
                 <option>Worldwide 3</option>
               </select>
             </div>
             <div className="icon is-small is-left">
               <img src={pinIcon} width={'18px'} alt="map marker" />
             </div>
           </div>
           <div className="control page-creatives-sidebar-card-control is-bordered-bottom has-icons-left m-b-0">
             <div className="select page-creatives-sidebar-card-control-select">
               <select className="has-background-white has-text-grey-dark is-borderless">
                 <option>Availability 1</option>
                 <option>Availability 2</option>
                 <option>Availability 3</option>
               </select>
             </div>
             <div className="icon is-small is-left">
               <img src={calendarIcon} width={'18px'} alt="calendar" />
             </div>
           </div>
           <div className="control page-creatives-sidebar-card-control has-icons-left">
             <div className="select page-creatives-sidebar-card-control-select">
               <select className="has-background-white has-text-grey-dark is-borderless">
                 <option>Profession 1</option>
                 <option>Profession 2</option>
                 <option>Profession 3</option>
               </select>
             </div>
             <div className="icon is-small is-left">
               <img src={briefcaseIcon} width={'18px'} alt="calendar" />
             </div>
           </div>
         </div>
       </div>
      </div> */}
    </div>
  );
};

export default CreativesFilter;
