import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { algoliasearch } from 'algoliasearch';
import Autocomplete from './Autocomplete';
import SearchItem from './SearchItem';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// I Algolia api key or id is missing we are mocking the call because context is using it
// if the keys aren't available it defaults to our orginal search bar.
// Notes: consider adding a feature flag in the future
const createSearchClient = () => {
  const appId = process.env.REACT_APP_ALGOLIA_APP_ID ?? '';
  const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY ?? '';

  if (appId && apiKey) {
    return algoliasearch(appId, apiKey) as any;
  } else {
    return {
      transporter: {} as any,
      appId: '',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      clearCache: () => {},
      _ua: '',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      addAlgoliaAgent: () => {},
      initIndex: () => ({} as any),
      search: async () => ({
        results: [
          {
            hits: [],
            nbHits: 0,
            page: 0
          }
        ]
      }),
      searchForFacetValues: async () => [],
      getRecommendations: async () => ({ results: [] }),
      multiple: async () => ({ results: [] }),
      multipleGetObjects: async () => ({ results: [] }),
      multipleQueries: async () => ({ results: [] })
    } as any;
  }
};

export const searchClient = createSearchClient();

function Core({ pathname }) {
  const [pc, setPc] = useState(true);
  const history = useHistory();

  useEffect(() => {
    screen.width < 900 ? setPc(false) : setPc(true);
  }, []);

  const handleShowAllResults = (query: string) => {
    history.push(`/creatives?q=${encodeURIComponent(query)}`);
  };

  return (
    <div className="navbar-search" style={{ width: '500px', padding: '10px' }}>
      <Autocomplete
        openOnFocus={pc}
        placeholder={`Search for ${pathname}`}
        getSources={({ query }: { query: string }) => [
          {
            sourceId: pathname,
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: pathname,
                    params: {
                      query,
                      hitsPerPage: 10
                    }
                  }
                ]
              });
            },
            templates: {
              item({ item, components }) {
                return (
                  <SearchItem
                    hit={item}
                    components={components}
                    pathname={pathname}
                  />
                );
              },
              noResults() {
                return 'No results found!';
              },
              footer({ state }) {
                // Hide the show all results button if we are searching for projects until the new page for displaying
                // both creatives and projects together is created
                if (!state.query || pathname === 'project') return null;

                return (
                  <button
                    className="search-show-all-btn"
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      padding: '10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleShowAllResults(state.query)}
                  >
                    Show All Results
                  </button>
                );
              }
            }
          }
        ]}
      />
    </div>
  );
}

export default Core;
